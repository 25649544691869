import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */

export const _frontmatter = {
  "title": "Introduction to enableHR API",
  "slug": "introduction-to-enablehr-api"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Introduction to enableHR API`}</h1>
    <p>{`Websites are designed to cater to people's strengths. Humans have an incredible ability to take visual information, combine it with our experiences to derive meaning, and then act on that meaning. It's why you can look at a form on a website and know that the little box with the phrase "First Name" above it means you are supposed to type in the word you use to informally identify yourself.`}</p>
    <p>{`Yet, what happens when you face a very time-intensive task, like copying the contact info for a thousand customers from one site to another? You would love to delegate this work to a computer so it can be done quickly and accurately. Unfortunately, the characteristics that make websites optimal for humans make them difficult for computers to use.`}</p>
    <p>{`The solution is an API. An API is the tool that makes a website's data digestible for a computer. Through it, a computer can view and edit data, just like a person can by loading pages and submitting forms.`}</p>
    <p>{`Making data easier to work with is good because it means people can write software to automate tedious and labor-intensive tasks. What might take a human hours to accomplish can take a computer seconds through an API.`}</p>
    <h2>{`How An API Is Used`}</h2>
    <p>{`When two systems (websites, desktops, smartphones) link up through an API, we say they are "integrated." In an integration, you have two sides, each with a special name. One side we have already talked about: the server. This is the side that actually provides the API. It helps to remember that the API is simply another program running on the server 3. It may be part of the same program that handles web traffic, or it can be a completely separate one. In either case, it is sitting, waiting for others to ask it for data.`}</p>
    <p>{`The other side is the "client." This is a separate program that knows what data is available through the API and can manipulate it, typically at the request of a user. A great example is a smartphone app that syncs with a website. When you push the refresh button in your app, it talks to a server via an API and fetches the newest info.`}</p>
    <p>{`The same principle applies to websites that are integrated. When one site pulls in data from the other, the site providing the data is acting as the server, and the site fetching the data is the client.`}</p>
    <h2>{`The enableHR API Ecosystem`}</h2>
    <p>{`Building an OpenAPI is a huge undertaking, so we’re releasing it in stages. Each release improves and expands the number of things you and your business can do with enableHR.`}</p>
    <p>{`There are still several months of work ahead for the team to further
extend the functionality in our API, but we’re excited about what
we’ve achieved so far – and what you can build on today. We have
deployed several releases of the API, and we are aiming at starting to
develop API Connectors in the 2nd half of the year 2021. We haven’t
finalised yet the systems we will be building connectors to, nor the
order of priority, and this is will be communicated by the end of
April 2021.`}</p>
    <p>{`The table below shows the information that is currently exposed through the API and the short-term roadmap.`}</p>
    <h3>{`Currently available:`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://developer.test.enablehr.com/development/ListBranches/get",
            "title": "Accounts: List Branches"
          }}>{`Accounts: List Branches`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Accounts: Retrieve details of a Branch`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Accounts: List Reference Data: Genders`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Accounts: List Reference Data: Honorifics`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Accounts: List Reference Data: Employment Types`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Accounts: List Reference Data: Employment Status`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Create a new employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Update an existing employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: List all employees in the account`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Retrieve details of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Update an existing employee - Adding Employment Status`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Terminate an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Transfer an employee (Move Everything)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): Retrieve Tax Details to an employee record`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): Maintain Tax Details to an employee record`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): Retrieve details of all Bank Accounts of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): Maintain Bank Accounts of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): Retrieve leave balances of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): Maintain leave balances of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): List all Superannuation Memberships of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): Retrieve Superannuation Memberships of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): Update a Superannuation Memberships of an employee (Regulated Funds)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): Remove a Superannuation Memberships from an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (AU): Create Superannuation Memberships for an employee (Regulated Funds)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (NZ): Retrieve Kiwi Saver details of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (NZ): Update Kiwi Saver details of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (NZ): Retrieve Tax Details of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (NZ): Update Tax Details of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (NZ): Retrieve details of all Bank Accounts of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (NZ): Update details of all Bank Accounts of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (NZ): Retrieve leave balances of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Payroll (NZ): Update leave balances of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Candidates: List candidate`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Candidates: Add new candidate`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Candidates: Retrieve & update permanent field`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Candidates: Convert candidate to employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Candidates: Merge 2 candidate`}</p>
      </li>
    </ul>
    <h4>{`Coming soon:`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Employees: List/Retrieve/Update Custom Fields of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: List/Retrieve/Add/Update/Delete roles and groups`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: List/Retrieve/Add/Update/Delete competencies`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Manage roles and competencies for an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Create a new employee - Adding Emergency Contact`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Update an existing employee - Adding Emergency Contact`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Retrieve details of an employee - Adding Emergency Contact`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: List & retrieve notes for an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Add & update note to an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Check for duplicate employee records`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Merge 2 employees`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Multi-branch linking of an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: List & retrieve notes for a candidate`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Add a note to a candidate`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Transfer to new branch (new copy)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Transfer to new branch (move no trace)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Employees: Delete an employee`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Candidates: Retrieve & update custom fields`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Candidates: Multi-branch linking of a candidate`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Candidates: Delete a candidate`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Users: List and manage ESS users`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Users: List and manage enableHR users`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      